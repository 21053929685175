import React, { useEffect } from "react";
// @ts-ignore
import loadable from "@loadable/component";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./components/Loader";
import Home from "./pages/Home";
import { RecoilRoot, useSetRecoilState } from "recoil";
import RecoilStates from "./utils/recoil";
import useFetch from "./hooks/useFetch";
import { LamelaDropdownType } from "./types/types";

const AsyncApartment = loadable(() => import(/* webpackChunkName:'Apartment' */ "./pages/Apartment"), {
  fallback: Loader,
});

const AsyncApartments = loadable(() => import(/* webpackChunkName:'Apartments' */ "./pages/Apartments"), {
  fallback: Loader,
});

const AsyncContact = loadable(() => import(/* webpackChunkName:'Contact' */ "./pages/Contact"), {
  fallback: Loader,
});

const AsyncFloor = loadable(() => import(/* webpackChunkName:'Floor' */ "./pages/Floor"), {
  fallback: Loader,
});

const AsyncGallery = loadable(() => import(/* webpackChunkName:'Gallery' */ "./pages/Gallery"), {
  fallback: Loader,
});

const AsyncLamela = loadable(() => import(/* webpackChunkName:'Lamela' */ "./pages/Lamela"), {
  fallback: Loader,
});

const AsyncLocation = loadable(() => import(/* webpackChunkName:'Location' */ "./pages/Location"), {
  fallback: Loader,
});

const AsyncProject = loadable(() => import(/* webpackChunkName:'Project' */ "./pages/Project"), {
  fallback: Loader,
});

const AsyncSpecification = loadable(() => import(/* webpackChunkName:'Specification' */ "./pages/Specification"), {
  fallback: Loader,
});

const AsyncParkings = loadable(() => import(/* webpackChunkName:'Specification' */ "./pages/Parkings"), {
  fallback: Loader,
});

const AsyncParkingsType = loadable(() => import(/* webpackChunkName:'Specification' */ "./pages/ParkingsType"), {
  fallback: Loader,
});

const AsyncParking = loadable(() => import(/* webpackChunkName:'Specification' */ "./pages/Parking"), {
  fallback: Loader,
});

const DropdownFetcher = () => {
  const setDropdown = useSetRecoilState(RecoilStates.dropdownState);
  const soldResult = useFetch<Array<LamelaDropdownType>>(process.env.REACT_APP_STRAPI?.concat?.(`/lamela-dropdowns`));
  useEffect(() => {
    if (soldResult.data) {
      setDropdown(soldResult.data);
    }
  }, [setDropdown, soldResult]);
  return null;
};

function App() {
  return (
    <RecoilRoot>
      <DropdownFetcher />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="apartments" element={<AsyncApartments />} />
          <Route path="parkings" element={<AsyncParkingsType />}>
            <Route path=":type" element={<AsyncParkingsType />} />
            <Route path=":type/:lamelaId" element={<AsyncParkingsType />} />
          </Route>
          <Route path="parking" element={<AsyncParking />}>
            <Route path=":section/:number" element={<AsyncParking />} />
          </Route>
          <Route path="garage" element={<AsyncParking isGarage />}>
            <Route path=":garage/:number" element={<AsyncParking isGarage />} />
          </Route>
          <Route path="lamela" element={<AsyncLamela />}>
            <Route path=":lamelaId" element={<AsyncLamela />} />
          </Route>
          <Route path="floor" element={<AsyncFloor />}>
            <Route path=":lamelaId/:floorId" element={<AsyncFloor />} />
          </Route>
          <Route path="apartment" element={<AsyncApartment />}>
            <Route path=":lamelaId/:floorId/:apartmentId" element={<AsyncApartment />} />
          </Route>
          <Route path="project" element={<AsyncProject />} />
          <Route path="location" element={<AsyncLocation />} />
          <Route path="specification" element={<AsyncSpecification />} />
          <Route path="gallery" element={<AsyncGallery />} />
          <Route path="contact" element={<AsyncContact />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
